import React, { FunctionComponent } from 'react';
import { Card } from 'antd';
import ExternalLink from '../ExternalLink';
import { FAUCET_API } from '../../data/faucet';
import { GithubOutlined, TwitterOutlined, ApiOutlined, BarsOutlined } from '@ant-design/icons';

const Socials: FunctionComponent = () => (
    <Card style={{ fontSize: '24px' }}>
        <ExternalLink to="https://app.mycrypto.com/faucet">
            <BarsOutlined />
        </ExternalLink>
        <ExternalLink to="https://github.com/MyCryptoHQ/mycrypto-faucet">
            <GithubOutlined style={{ marginLeft: '15px' }} />
        </ExternalLink>
        <ExternalLink to="https://twitter.com/search?q=I%20just%20got%20testnet%20ETH%20using%20%40MyCrypto%27s%20Faucet&src=typed_query&f=live">
            <TwitterOutlined style={{ marginLeft: '15px' }} />
        </ExternalLink>
        <ExternalLink to={FAUCET_API}>
            <ApiOutlined style={{ marginLeft: '15px' }} />
        </ExternalLink>
    </Card>
);

export default Socials;
