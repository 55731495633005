import React, { FunctionComponent, Fragment } from 'react';
import { networkColors } from '../Network';
import { FAUCET_NETWORK, FAUCET_NETWORKS, FAUCET_ADDRESS } from '../../data/faucet';
import dayjs from '../../utils/dayjs';
import { NetworkTransaction } from '../../types/transaction';

interface DataItem {
    date: string;
    network: FAUCET_NETWORK;
    count: number;
}

interface Props {
    transactions: NetworkTransaction[];
}

const startDate = '2020-12-17';

const TransactionGraph: FunctionComponent<Props> = ({ transactions }: Props) => {
    const data: DataItem[] = [];
    const launchDate = dayjs(startDate);
    const launchTimeDays = dayjs().diff(startDate, 'day');

    for (let i = 0; i <= launchTimeDays; i++) {
        const date = launchDate.add(i, 'day');
        FAUCET_NETWORKS.map(faucetNetwork => {
            data.push({
                date: date.format('YYYY-MM-DD'),
                network: faucetNetwork,
                count: 0
            });
        });
    }

    transactions
        .filter(tx => {
            return tx.from && tx.from.toLowerCase() === FAUCET_ADDRESS.toLowerCase();
        })
        .map(tx => {
            const date = dayjs(parseInt(tx.timeStamp, 10) * 1000).format('YYYY-MM-DD');
            const dateEntry = data.find(
                entry => entry.date === date && entry.network === tx.network
            );
            if (dateEntry) {
                dateEntry.count++;
            }
        });

    if (typeof window === 'undefined') {
        return <Fragment />;
    } else {
        const { Chart, Tooltip, Axis, Line, Legend } = require('viser-react');
        return (
            <Chart forceFit={true} data={data} height={400}>
                <Tooltip />
                <Legend position="bottom-center" />
                <Axis dataKey="date" />
                <Axis dataKey="count" />
                <Line position="date*count" color={['network', networkColors]} />
            </Chart>
        );
    }
};

export default TransactionGraph;
