import React, { FunctionComponent } from 'react';
import Layout from '../components/Layout';
import MetaData from '../components/MetaData';
import ReloadButton from '../components/ReloadButton';
import BalanceTable from '../components/BalanceTable';
import TransactionGraph from '../components/TransactionGraph';
import Status from '../components/Status';
import Socials from '../components/Socials';
import { Row, Col, Typography } from 'antd';
import { useData } from '../hooks';

import '../sass/index.scss';

const { Title } = Typography;

const Index: FunctionComponent = () => {
    const { loading, status, balances, transactions, statusUpdated, updateData } = useData();

    return (
        <Layout>
            <MetaData />
            <Row>
                <Col offset={2} span={20}>
                    <Title level={2}>Overview</Title>
                    <ReloadButton loading={loading} action={updateData} />
                    <Row>
                        <Col span={16}>
                            <BalanceTable balances={balances} />
                        </Col>
                        <Col offset={1} span={7}>
                            <Status status={status} updated={statusUpdated} />
                            <br />
                            <Socials />
                        </Col>
                    </Row>
                    <br />
                    <TransactionGraph transactions={transactions} />
                </Col>
            </Row>
        </Layout>
    );
};

export default Index;
