import React, { FunctionComponent } from 'react';
import { Table } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import Network from '../Network';
import ExternalLink from '../ExternalLink';
import { FAUCET_NETWORK, FAUCET_NETWORKS, FAUCET_ADDRESS } from '../../data/faucet';
import { utils } from 'ethers';
import { NetworkBalances } from '../../store/faucet';
import { ColumnsType } from 'antd/es/table';

interface Props {
    balances: NetworkBalances;
}

interface NetworkBalanceEntry {
    key: number;
    network: FAUCET_NETWORK;
    balance: string;
    address: string;
}

const columns: ColumnsType<NetworkBalanceEntry> = [
    {
        title: 'Network',
        dataIndex: 'network',
        render: network => <Network type={network} />
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        render: balance => <span>{balance} ETH</span>
    },
    {
        title: 'Address',
        dataIndex: 'address',
        render: (address, entry) => (
            <ExternalLink to={`https://${entry.network}.etherscan.io/address/${address}`}>
                <LinkOutlined />
            </ExternalLink>
        )
    }
];

const BalanceTable: FunctionComponent<Props> = ({ balances }: Props) => {
    const data = FAUCET_NETWORKS.map((faucetNetwork, index) => {
        let formattedBalance = '...';
        const weiBalance = balances[faucetNetwork];

        if (typeof weiBalance !== 'undefined') {
            formattedBalance = utils.formatEther(weiBalance);
        }

        return {
            key: index,
            network: faucetNetwork,
            balance: formattedBalance,
            address: FAUCET_ADDRESS
        };
    });

    return <Table pagination={false} columns={columns} dataSource={data} />;
};

export default BalanceTable;
