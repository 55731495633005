import React, { FunctionComponent } from 'react';
import { Card, Statistic } from 'antd';
import { green, red, grey } from '@ant-design/colors';
import { useTime } from '../../hooks';
import { FAUCET_API } from '../../data/faucet';
import { FaucetAPIStatus } from '../../store/faucet';
import dayjs from '../../utils/dayjs';
import ExternalLink from '../ExternalLink';
import { RightSquareOutlined } from '@ant-design/icons';

const getStatusText = (status: FaucetAPIStatus) => {
    switch (status) {
        case FaucetAPIStatus.ONLINE:
            return 'Online';
        case FaucetAPIStatus.OFFLINE:
            return 'Offline';
        case FaucetAPIStatus.UNKNOWN:
        default:
            return 'Unknown';
    }
};

const getStatusColor = (status: FaucetAPIStatus) => {
    switch (status) {
        case FaucetAPIStatus.ONLINE:
            return green.primary;
        case FaucetAPIStatus.OFFLINE:
            return red.primary;
        case FaucetAPIStatus.UNKNOWN:
        default:
            return grey.primary;
    }
};

interface Props {
    status: FaucetAPIStatus;
    updated: number;
}

const Status: FunctionComponent<Props> = ({ status, updated }: Props) => {
    useTime(1000);

    return (
        <Card>
            <Statistic
                title={
                    <span>
                        API Status
                        <span style={{ float: 'right' }}>
                            <ExternalLink to={`${FAUCET_API}/ping`}>
                                <RightSquareOutlined />
                            </ExternalLink>
                        </span>
                    </span>
                }
                value={getStatusText(status)}
                valueStyle={{ color: getStatusColor(status) }}
            />
            <p style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{dayjs(updated).fromNow()}</p>
        </Card>
    );
};

export default Status;
